import {
  Component,
  ChangeDetectionStrategy,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subscription } from "rxjs";
import { SplashScreenService } from "./_metronic/partials/layout/splash-screen/splash-screen.service";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "body[root]",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  constructor(
    private splashScreenService: SplashScreenService,
    private router: Router, // private tableService: TableExtendedService
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService
  ) {

  }
  openWpp = false;

  toggle() {
    this.openWpp = !this.openWpp;
    this.changeDetectorRef.detectChanges();
  }

  wpp_web =
    "https://web.whatsapp.com/send?phone=551195046-5213&text=Olá, tenho dúvidas sobre como fazer o testamento.";
  wpp_mobile =
    "https://api.whatsapp.com/send?phone=5511950465213&text=Ol%C3%A1%2C%20tenho%20d%C3%BAvidas%20sobre%20como%20fazer%20o%20testamento.";
  link_wpp = window.screen.width < 768 ? this.wpp_mobile : this.wpp_web;

  ngOnInit() {
    const routerSubscription = this.router.events.subscribe((event) => {
      const browserLang = this.translateService.getBrowserLang();
      this.translateService.use(browserLang.match(/en|pt/) ? browserLang : 'en');
      //

      if (event instanceof NavigationEnd) {
        // clear filtration paginations and others
        // this.tableService.setDefaults();
        // hide splash screen
        this.splashScreenService.hide();

        // scroll to top on every route change
        window.scrollTo(0, 0);

        // to display back the body content
        setTimeout(() => {
          document.body.classList.add("page-loaded");
        }, 500);
      }
    });
    this.unsubscribe.push(routerSubscription);
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
