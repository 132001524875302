import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';
import { SocialUser } from 'angularx-social-login';
import { TicketModel } from '../../_models/ticket.models';

const API_USERS_URL = `${environment.apiUrl}/users`;
const API_OAUTH_URL = `${environment.apiUrl}/oauth`;
const API_BASE_URL = `${environment.apiUrl}`;
const API_STAFF_URL = `${environment.apiUrl}/staff`;

@Injectable({
  providedIn: "root",
})
export class AuthHTTPService {
  constructor(private http: HttpClient) {}

  // public methods
  login(socialUser: SocialUser): Observable<any> {
    return this.http.post<AuthModel>(`${API_OAUTH_URL}/login`, socialUser);
  }

  // public methods
  fakeLogin(body: any): Observable<any> {
    return this.http.post<AuthModel>(`${API_BASE_URL}/login-local`, body);
  }
  loginLocal(body: any): Observable<any> {
    return this.http.post<AuthModel>(`${API_BASE_URL}/login`, body);
  }
  // public methods
  recoveryPass(body: any): Observable<any> {
    return this.http.post<AuthModel>(
      `${API_BASE_URL}/users/recovery-password`,
      body
    );
  }
  // public methods
  checkCode(body: any): Observable<any> {
    return this.http.post<AuthModel>(`${API_BASE_URL}/check/code`, body);
  }

  checkCustomer(body: any): Observable<any> {
    return this.http.post<AuthModel>(`${API_BASE_URL}/check/customer`, body);
  }

  // CREATE =>  POST: add a new user to the server
  createUserPassword(user: UserModel): Observable<any> {
    return this.http.post(`${API_USERS_URL}/new/customer`, user);
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<any> {
    return this.http.post(`${API_USERS_URL}/new/p`, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      email,
    });
  }

  createTicketNotAuthenticated(ticket: TicketModel): Observable<TicketModel> {
    return this.http.post<TicketModel>(`${API_STAFF_URL}/customer/no-auth/ticket`, ticket);
  }

  getUserByToken(token): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      token: `${token}`,
    });
    return this.http.get<UserModel>(`${API_BASE_URL}/check/token`, {
      headers: httpHeaders,
    });
  }

  getUserLogged(auth: AuthModel): Observable<UserModel> {
    const httpHeaders = new HttpHeaders({
      token: `${auth.token}`,
      id: `${auth.id}`,
    });
    return this.http.get<UserModel>(`${API_USERS_URL}/resume/${auth.id}`, {
      headers: httpHeaders,
    });
  }
}
