export class AddressModel {
  addressLine: string;
  country?: string;
  city: string;
  state: string;
  postCode: string;
  houseNumber: string;
  neighborhood: string;
  complement?: string;
  more?: string;
}
