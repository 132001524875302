export class AuthModel {
  token: string;
  id: number;
  permission: number;

  setAuth(auth: any) {
    this.token = auth.token;
    this.id = auth.id;
    this.permission = auth.permission;
  }
}
