import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RDSEvent } from './model/rds-events.model';
import { AuthService } from '../auth';
import { delay } from 'rxjs/operators';

const API_BASE_URL = `${environment.apiUrl}`;
@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private http: HttpClient, private authService: AuthService) { }

  sendEvent(body: RDSEvent) {
    // return this.http.post(environment.RDS_BASE_URL + 'platform/conversions?api_key=' + environment.RDS_KEY, body);
    return null
  }
  sendFormIntegration(body: RDSEvent) {


    return this.http.post(`${API_BASE_URL}/users/event-mail`, { ...body, token_rdstation: environment.RDS_TOKEN, form_url: window.location.href, identificador: '[B2C] ' + body.identificador }, {
      headers: this.getHeader()
    })


    return this.http.post('https://www.rdstation.com.br/api/1.3/form-integrations', { ...body, token_rdstation: environment.RDS_TOKEN, form_url: window.location.href, identificador: '[B2C] ' + body.identificador });
  }

  getHeader(): HttpHeaders {
    const user = this.authService.getAuthFromLocalStorage();
    return new HttpHeaders({
      token: `${user.token}`,
      id: `${user.id}`,
    });
  }
}
