import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class BLHttpInterceptor implements HttpInterceptor {
    [x: string]: any;

    /** lista de rotas a serem ignoradas pela mensagem automatica de sucesso */
    private IGNORE_LIST = [
        { m: 'get', p: '.*' },
        { m: 'post', p: '.*' },
        { m: 'put', p: '.*' },
        { m: 'delete', p: '.*' },
        { m: 'options', p: '.*' }
    ];

    constructor(@Inject(Injector) private readonly injector: Injector, private router: Router) {
    }

    private get toastService() {
        return this.injector.get(ToastrService);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const started = Date.now();
        let ok: string;
        let status = 1;
        let message = null;
        // this._fuseProgressBarService.show();

        return next.handle(req).pipe(
            tap(
                // Succeeds when there is a response; ignore other events
                event => {
                    this.status = 1;
                    const m = req.method.toLocaleLowerCase();
                    const p = req.url;
                    const toIgnore = this.IGNORE_LIST.filter(f => f.m.toLocaleLowerCase().trim() === m && p.match(f.p));
                    // if (toIgnore.length === 0) {
                    //     message = "Operação realizada com sucesso.";
                    // }
                },
                // Operation failed; error is an HttpErrorResponse
                err => {
                    this.status = 0;
                    if (err.status === 0 && !err.url.endsWith('profile') && !err.url.contains('viacep')) {
                        message = 'Houve uma falha ao conectar com o servidor. Verifique a sua conexão com a internet e caso necessário contate nosso time de suporte.';
                    }

                    /**
                     * É esperado que o método de check de 401 para informar que não há autenticação.
                     * Para os outros casos isso representa uma falha de acesso.
                     */
                    if ((err.status === 401 || err.status === 403)) {
                        if (err.error === "Invalid credentials") {
                            message = 'Autenticação não encontrada. Por favor, verifique seu E-mail e sua senha e faça o login novamente.';
                        } else if (err.error === "Expire code") {
                            message = 'Código expirado. Por favor, realize o seu cadastro novamente!';
                            return err
                        } else if (err.error === "Not Verified") {
                            return err
                            // this.router.navigate(['/auth/verfify']);
                        } else if (err.error === "Wrong code") {
                            message = 'O Código digitado não confere, por favor tente novamente.';
                            return err
                        }
                        else if (err?.error?.error === "This request requires an auth_token") {
                            message = null;
                            return err
                        }
                        else {
                            message = 'Autenticação não encontrada. Faça o login novamente por favor.';
                        }
                    }

                    if (err.status === 500 && !window.location.pathname.includes("payment")) {
                        // if (!err.error.message) {
                        message = `Houve uma falha interna no servidor. Entre em contato com a nossa equipe de suporte. Mensagem de erro: '${err.message}'`;
                        // } else {
                        // message = `${err.error.message}`;
                        // }
                    }

                    if (err.status === 422) {
                        message = `${err.error.message}`;
                    }
                }
            ),
            // Log when response observable either completes or errors
            finalize(() => {
                // this._fuseProgressBarService.hide();
                if (message != null) {
                    // if (this.status)
                    //     this._toast.success(message);
                    // else
                    this.toastService.error(message);
                }
                const elapsed = Date.now() - started;
                const msg = `${req.method} "${req.urlWithParams}"
                ${ok} in ${elapsed} ms.`;
            })
        );
    }
}
