import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TermsComponent } from './terms.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    TermsComponent,
  ],
  imports: [CommonModule],
  exports: [
    TermsComponent,
    CommonModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class TermsModule { }