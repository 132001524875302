import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { PeopleService } from 'src/app/pages/people/services/people.service';
import { AuthService, UserModel } from '..';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  @ViewChild("content") modalContent: TemplateRef<any>;
  constructor(
    private modalService: NgbModal,
     private service: PeopleService, 
     private auth: AuthService,
     private router: Router,) { }

  ngOnInit(): void {
    this.service.getMe().subscribe((ret: any) => {
      if (!ret["acceptedTerm"] && !this.auth.getFakeLogin()) {
        this.open(this.modalContent);
         setTimeout(() => {
              const a = document.getElementById('top-doc')
              a.scrollIntoView({ behavior: 'smooth', block: 'center' })      
            }, 300);
      }
    })

  }
  isLoading = new BehaviorSubject<boolean>(false);
  closeResult = '';
  modalOpenRef: any
  open(content) {
    this.modalOpenRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-terms', backdrop: 'static',
      keyboard: false
    })
  }



  updateTermsUser(): void {
    this.isLoading.next(true);
    const payload = new UserModel();
    payload.acceptedTerm = true;
    delete payload.requestUpdate
    this.service.updateTerms(payload).subscribe((ret: any) => {
      this.isLoading.next(false);
      this.modalOpenRef.close();
      this.router.navigate(['/home']);
    })
  }

  logout() {
    this.auth.logout();
    document.location.reload();
  }

}
