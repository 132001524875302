export class DocumentsModel {
    documentFront: string
    documentBack: string
    public constructor(model?: DocumentsModel) {
        if (model) {
            this.populate(model);
        }
    }

    public populate(ent: DocumentsModel): void {
        this.documentFront = ent.documentFront;
        this.documentBack = ent.documentBack;

    }
}


